import './App.css';
import Coming from './component/coming/coming';
import Widget from './component/coming/widget/widget';

function App() {
  return (
    <div>
      <Coming />     
      <Widget />
    </div>
  );
}

export default App;
