import React, { useState } from "react";
import "./widget.css";
import {
  FaAngleRight,
  FaAngleLeft,
  FaDiscord,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
  FaTelegram,
} from "react-icons/fa";

const Widget = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="widget-wraper">
      <div className={`audio-player-container ${isOpen ? "open" : "close"}`}>
        <div className="audio-player-wraper">
          <div className="audio-controls">
            <button className="prev">
              <a
                className="anchor"
                href="https://discord.com/invite/fNHbCM4h"
                target="new"
              >
                <FaDiscord />
              </a>
            </button>
            <button className="prev">
              <a
                className="anchor"
                href="https://www.instagram.com/drippzone/"
                target="new"
              >
                <FaInstagram />
              </a>
            </button>
            <button className="prev">
              <a
                className="anchor"
                href="https://twitter.com/dripp_zone"
                target="new"
              >
                <FaTwitter />
              </a>
            </button>
            <button className="prev">
              <a
                className="anchor"
                href="https://www.linkedin.com/company/dripp-zone/about/?viewAsMember=true "
                target="new"
              >
                <FaLinkedin />
              </a>
            </button>
            <button className="prev">
              <a
                className="anchor"
                href="https://t.me/drippzone"
                target="new"
              >
                <FaTelegram />
              </a>
            </button>
          </div>
        </div>
        <div className="toggle">
          <div onClick={handleToggle} className="toggle-icon">
            {isOpen ? <FaAngleLeft /> : <FaAngleRight />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Widget;
