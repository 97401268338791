import React from 'react';
import Bg from '../coming/Bg.mp4';
import './coming.css';

function Coming() {
  return (
    <div className='background'>
      <video className="banner" autoPlay loop muted src={Bg} type="video/mp4" />
    </div>
  )
}

export default Coming 